.process-offcanvas{
    background-color: rgba(10,20,30,0.9);
}
.task-list-offcanvas{
    max-width:280px;
}
.dataset-list-offcanvas{
    width:500px;
}

.select__control, .select__menu {
    display: block;
    width: 100%;
    max-width: 600px;
    font-size: 1rem;
    color: #fff;
    background-color: #231f3d !important;
    /* background-clip: padding-box; */
    border: none;
    appearance: none;
    border-radius: 5px;
    box-shadow: 0 3px 5px rgb(0 0 0 / 20%) inset;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.chonky-black *{
    color: #fff;
}

.chonky-black .chonky-chonkyRoot{
    background: rgba(0,0,0,0.2);
    border: none;
}

.chonky-black .chonky-searchFieldInput{
    position: relative;
    color: #fff;
    z-index: 1;
}

.chonky-black .MuiOutlinedInput-notchedOutline{
    background: rgba(0,0,0,0.2);
    border-color:transparent;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2) inset;
    z-index: -1;
}

.dropzone-black .dropzone-ui{
    color: #fff;
    background: #312F4D;
    border:none;
}

.dropzone-black .dropzone-ui .file-item-name{
    color: #bbb;
}

.MuiMenu-list *{
    color: #333;
}
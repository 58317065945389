.node-graph-layout {
    position: relative;
}

.node-graph-layout .controls {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 4;
}

/*.node-graph-layout .sidebar {*/
/*    position: absolute;*/
/*    bottom: 5px;*/
/*    width: 100%;*/
/*    z-index: 4;*/
/*}*/


.dndflow {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    height: 100%;
}

.dndflow aside {
    border: 1px solid #eee;
    padding: 5px 10px;
    font-size: 12px;
    background: #fcfcfc;
}

.dndflow aside .description {
    margin-bottom: 10px;
}

.dnd-sidebar{
    margin-top:10px;
}

.dndflow .dndnode {
    display: flex;
    float: left;
    height: 40px;
    margin: 5px auto 10px;
    background: #312F4D;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    cursor: grab;
    width: 100%;
    max-width: 220px;
    text-align: center;
    box-shadow: 0 5px 10px rgba(0,0,0,0.4);
}

.dndflow .dndnode.input {
    border-color: #0041d0;
}

.dndflow .dndnode.output {
    border-color: #ff0072;
}

.dndflow .dndnode.selectorNode {
    border-color: #40ff00;
}

.dndflow .reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
}

.dndflow .selectall {
    margin-top: 10px;
}


/*.react-flow__node-default.selectable.selected, .react-flow__node-group.selectable.selected, .react-flow__node-input.selectable.selected, .react-flow__node-output.selectable.selected {*/
/*    box-shadow: 0 0 0 2px #ba0909 !important;*/
/*}*/

.touchdevice-flow .react-flow__handle {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #84EAEC !important;
}

.process_graph .react-flow__node{
    font-size: 0.75rem;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.4);
}
.process_graph .react-flow__node strong{
    font-family: "SCDream3", "sans-serif";
    font-weight: 400;
}

.process_graph .react-flow__node-input{
    background: #514F6B;
    color: #84EAEC;
    text-transform: uppercase;
}

.process_graph .react-flow__node-DF_NODE{
    background: #312F4D;
    color: #fff;
    border: none;
}

.process_graph .react-flow__edge-path{
    stroke: #84EAEC;
}

.process_graph .react-flow__attribution{
    background: transparent;
}

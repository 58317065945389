.dashboard-circulargraph {
    display: flex;
    position: relative;
    justify-content: center;
    flex: 1;
}
.dashboard-circulargraph.main{
    min-width: 430px;
    height: 430px;
    min-height: 430px;
}
.dashboard-circulargraph.sub{
    min-width: 302px;
    height: 302px;
    min-height: 302px;
    margin-top: 1.25rem;
}
.dashboard-circulargraph-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    border-radius: 50%;
    background: radial-gradient(
            rgba(0, 0, 0, 0.2) 60%,
            rgba(89, 159, 138, 0.1) 70%
    );
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
    color: #fff;
    z-index: 10;
}
.main .dashboard-circulargraph-info{
    width: 380px;
    height: 380px;
}
.sub .dashboard-circulargraph-info{
    width: 300px;
    height: 300px;
}
.main .dashboard-circulargraph-info .ttl h4 {
    font-family: "SCDream4";
    font-size: 1.125rem;
    margin: 0;
}
.dashboard-circulargraph-info .ttl span,
.dashboard-circulargraph-info .count-capacity{
    color: #bbb;
}
.main .dashboard-circulargraph-info .count {
    margin: 30px 0 60px;
}
.main .dashboard-circulargraph-info .count * {
    font-family: "SCDream1";
}
.main .dashboard-circulargraph-info .count span {
    margin: 0 0.2em;
}
.main .dashboard-circulargraph-info .count-usage {
    font-size: 3.125rem;
    color: #84eaec;
}
.main .dashboard-circulargraph-info .count-capacity {
    font-size: 1.875rem;
    color: #9ca0c9;
}
.sub .dashboard-circulargraph-info .count{
    margin: 0.3rem 0 0;
}
.sub .dashboard-circulargraph-info .percentage{
    font-family: "SCDream1";
    font-size: 3.75rem;
    color: #84EAEC;
    margin-bottom: 1.25rem;
}

.dashboard-circulargraph svg {
    width: 100%;
    transform: rotate(-90deg);
}
.dashboard-circulargraph circle {
    fill: transparent;
    stroke-width: 2px;
    stroke: #84eaec;
    stroke-linecap: round;
    transition: 2s;
}
.login-box,
.register-box {
    width: 100%;
    max-width: 500px;
}

.login-page,
.register-page {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: calc(100vh - 15rem);
    -ms-flex-pack: center;
    justify-content: center;
}

.login-box .dark-mode-logo {
    display: none;
}

.login-box .input-group-text {
    display: flex;
    align-items: center;
    padding: 0.675rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #ecf0f1;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.login-submit {
    position: relative;
}

.alert-login {
    position: absolute;
    bottom: 100%;
    left: 0;
    margin-bottom: 0.5em;
}

.navbar-overlap:after {
    content: "";
    height: 9rem;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: inherit;
    z-index: -1;
    box-shadow: inherit;
}

.nav-link-icon {
    width: 1.5rem;
    height: 1.25rem;
    -webkit-margin-end: 0.25rem;
    margin-inline-end: 0.25rem;
    opacity: 0.7;
}

.navbar-dark .navbar-nav .nav-link {
    color: #9ca0c9;
}

.navbar-dark .navbar-nav a.nav-link:focus,
.navbar-dark .navbar-nav a.nav-link:hover {
    color: #fff !important;
}

.navbar-dark .navbar-nav a.nav-link:not(.nav-logout) {
    overflow: hidden;
    padding: 0 2.5em;
}
.navbar-dark .navbar-nav a.nav-link:not(.nav-logout):focus,
.navbar-dark .navbar-nav a.nav-link:not(.nav-logout):hover {
    border-bottom: 2px solid #fff;
}
.navbar-dark .navbar-nav a.nav-link:not(.nav-logout)::after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 0%;
    border: none;
    background: radial-gradient(rgba(255, 255, 255, 0.3), transparent 70%);
    transition: 0.2s;
}
.navbar-dark .navbar-nav a.nav-link:focus::after,
.navbar-dark .navbar-nav a.nav-link:hover::after {
    height: 100%;
}

.navbar-dark .navbar-nav .nav-logout {
    opacity: 0.6;
    transition: 0.2s;
}
.navbar-dark .navbar-nav .nav-logout:hover {
    opacity: 1;
}

.navbar-dark .nav-user {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px 1.5rem 0 0;
    color: #fff;
}
.navbar-dark .nav-user img{
    opacity: 0.6;
}
.navbar-dark .nav-user strong{
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.05rem;
    margin: 0 2px 0 4px;
}
.navbar-dark .nav-user span{
    opacity: 0.6;
    font-size: 14px;
}

.page-title {
    font-size: 1.7rem;
}

.btn-secondary {
    background-color: #5a6667;
}

.navbar .navbar-nav .nav-link {
    display: flex;
    position: relative;
    height: 4.375rem;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.page-pretitle {
    font-size: 0.625rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    line-height: 1.6;
    color: #9ba6b3;
}

.navbar-dark {
    position: fixed;
    width: 100vw;

    top: 0;
    left: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    color: rgba(255, 255, 255, 0.7);
}
.navbar-dark::after {
    display: none;
}

@media (max-width: 1200px){
    .navbar-brand{
        margin-right: 1rem;
    }
    .navbar-dark .navbar-nav a.nav-link:not(.nav-logout){
        padding: 0 1em;
    }
    .navbar-dark .navbar-toggler{
        border: none;
    }
}

@media (min-width: 768px){
    .navbar-dark {
        min-width:1000px;
    }
}
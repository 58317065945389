.data-list{
    overflow: inherit;
    position: relative;
    width: 100%;
    border-radius: inherit;
    overflow-x: auto;
    overflow-y: hidden;
    min-height: 0;
    display: flex;
    flex-direction: column;
}

.data-list-header{
    display: flex;
    width: 100%;
    font-size: 13px;
    font-weight: 500;
}

.data-list-body{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border: 1px solid #405284;
    border-left: none;
    border-right: none;
    margin-bottom: 0.75rem;
}

.data-list .list-group{
    width: 100%;
}

.data-list-body .card-body:nth-child(odd) .list-group{
    background: rgba(0, 0, 0, 0.1);
}

.data-list-item{
    display: flex;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    min-width: 100px;
    padding: 6px 10px;
    color: #CED1F2;
    background: transparent;
    border: none;
}

.data-list-item:last-child{
    flex: 0.2;
}

.data-list-item .form-select{
    line-height: 1;
    font-size: 14px;
    padding: 0.7em 1em;
}

.data-list-item.disabled{
    background: transparent;
}

.data-list-item.disabled .form-select{
    background: #2f2c40;
    color: #aaa;
}

.data-list-add .btn-secondary{
    background-color: #201634;
    border: 1px solid #5259b9;
    color: #87bfff;
}
.dashboard-header {
    margin-top: 10rem;
    display: flex;
    flex-wrap: wrap;
}
.dashboard-header > div {
    display: flex;
    position: relative;
    flex: 1;
}
.dashboard-header img {
    max-width: 100%;
    height: auto;
    opacity: 0.7;
    animation: dashboardVisual 1.3s infinite alternate;
}
@keyframes dashboardVisual {
    0% {
        opacity: 0.7;
    }
    100% {
        opacity: 1;
    }
}
.dashboard-ttl {
    position: absolute;
    top: 50%;
    left: 2.5rem;
}
.dashboard-ttl h1 {
    font-family: "SCDream8";
    color: #fff;
    font-size: 3.75rem;
    line-height: 1.1em;
    text-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
}
.dashboard-ttl span {
    color: #bbb;
    font-size: 1rem;
}

.dashboard-summary {
    flex: 1;
    max-width: 17.5rem;
}
.dashboard-summary > a {
    display: block;
    padding: 1.25rem;
    min-width: 13.75rem;
    margin-bottom: 0.625rem;
    text-decoration: none;
    color: #fff;
    border: 1px solid transparent;
    border-radius: 5px;
    transition: 0.2s;
}
.dashboard-summary > a:hover {
    background-color: rgba(0, 0, 0, 0.2);
    border-color: #84eaec;
}
.dashboard-summary > a h4 {
    font-size: 1.125rem;
    margin: 0;
}
.dashboard-summary-count {
    padding: 0;
    margin: 0;
}
.dashboard-summary-count > li {
    color: #bbb;
    font-size: 1rem;
    margin-top: 0.625rem;
    display: flex;
    justify-content: space-between;
}
.dashboard-summary-count > li span {
    color: #ddd;
    margin-right: 0.2em;
}

@media (max-width: 1250px) {
    .dashboard-header > div {
        flex-direction: column;
    }
    .dashboard-summary {
        display: flex;
        flex-wrap: wrap;
        max-width: 100%;
    }
}

.dashboard-table,
.dashboard-graph{
    margin: 6.25rem 0 0;
}

.dashboard-slider{
    margin-bottom: 2.25rem;
}

.dashboard-slider .slick-list{
    width: calc(50vw - 86px);
    min-width: 416px;
    max-width: 682px;
}

.dashboard-slider .slick-prev,
.dashboard-slider .slick-next{
    width: 1rem;
    height: 1rem;
}

.dashboard-slider .slick-prev{
    left: -0.4rem;
}
.dashboard-slider .slick-next{
    right: -0.4rem;
}
.dashboard-slider .slick-prev::before,
.dashboard-slider .slick-next::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(45deg);
    width: 100%;
    height: 100%;
    border: 2px solid #84EAEC;
}
.dashboard-slider .slick-prev::before{
    border-top: none;
    border-right: none;
}
.dashboard-slider .slick-next::before{
    border-bottom: none;
    border-left: none;
}

.dashboard-table a{
    border: none;
    text-decoration: none;
    padding: 0 10px;
}

.dashboard-table .list{
    position: relative;
    background: #262B3E;
    border: 1px solid transparent;
    border-radius: 5px;
    overflow: hidden;
    padding: 20px;
    z-index: 0;
}

.dashboard-table .list .progress{
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0;
    height: 100%;
    z-index: -1;
    background: linear-gradient(transparent, #84EAEC);
    opacity: 0.3;
}
.dashboard-table .list:hover{
    border-color: #84EAEC;
}
.dashboard-table .list:hover .progress{
    opacity: 0.6;
}

.dashboard-table .list h5{
    font-family: "SCDream3", "sans-serif";
    color: #fff;
    position: relative;
    font-size: 1rem;
    padding-left: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.dashboard-table .list h5::before{
    content: '';
    position: absolute;
    width: 12px;
    height: 13px;
    top: 3px;
    left: 0;
    background: url("../../images/dashboard_list.png") no-repeat center;
}

.dashboard-table .list span{
    display: block;
    color: #84EAEC;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dashboard-graph-wrapper{
    width: calc(50% - 1.125rem);
}

.excelResult{
    background: #262b3e;
    border: 1px solid transparent;
    borderRadius: 5px;
    overflow: hidden;
    padding: 20px;
    position: relative;
    zIndex: 0;
}
.dft-node .node-header{
    height:20px;
    font-size:8px;
}
.dft-node .node-header-btn{
    border:1px solid #909090;
    border-radius: 3px;
    margin:1px;
    padding:1px;
}
.dft-node .node-header-state{
    margin-right:1px;
    padding-left:5px;
    padding-right:5px;
    border-radius: 3px;
    background-color: rgba(147, 182, 230, 0.5);
}

.dft-node .node-header-execute{
    background-color: #a4d198;
}
.dft-node .node-header-modify{
    background-color: #aec1c7;
}
.dft-node .node-header-delete{
    background-color: #d19898;
}

.dft-node .node-header-type{
    margin-left:1px;
    padding-left:5px;
    padding-right:5px;
    border-radius: 3px;
    background-color: rgba(179, 190, 205, 0.5);
}

.dft-node .node-body{
    padding-top:2px;
    padding-bottom:3px;
}
.dft-node .progress{
    height:0.15rem;
}

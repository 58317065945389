@font-face {
    font-family: "NanumBarunGothic";
    src: url("./font/NanumBarunGothic/NanumBarunGothic.eot");
    src: url("./font/NanumBarunGothic/NanumBarunGothic.eot")
            format("embedded-opentype"),
        url("./font/NanumBarunGothic/NanumBarunGothic.woff") format("woff");
}

@font-face {
    font-family: "NanumGothic";
    src: url("./font/NanumGothic/NanumGothic.eot");
    src: url("./font/NanumGothic/NanumGothic.eot") format("embedded-opentype"),
        url("./font/NanumGothic/NanumGothic.woff") format("woff");
}

@font-face {
    font-family: "NanumGothicBold";
    src: url("./font/NanumGothic/NanumGothicBold.eot");
    src: url("./font/NanumGothic/NanumGothicBold.eot")
            format("embedded-opentype"),
        url("./font/NanumGothic/NanumGothicBold.woff") format("woff");
}
@font-face {
    font-family: "SCDream1";
    src: url("./font/SCDream/SCDream1.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "SCDream3";
    src: url("./font/SCDream/SCDream3.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "SCDream4";
    src: url("./font/SCDream/SCDream4.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "SCDream5";
    src: url("./font/SCDream/SCDream5.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "SCDream8";
    src: url("./font/SCDream/SCDream8.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

* {
    font-family: "SCDream3", "serif";
}

/*스크롤바 디자인*/
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: #2A2743;
}
::-webkit-scrollbar-thumb{
    background: #405284;
    border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover{
    background: #61719D;
}

body{
    width: 100vw;
    min-width:1000px;
    color:#fff;
}

@media (min-width: 1001px){
    body{
        overflow-x: hidden;
    }
}

h1,
h2,
h3,
h4,
h5 {
    font-family: "SCDream5", "serif";
}

.content-wrapper {
    overflow-y: auto;
}


.page-header {
    display: flex;
    justify-content: space-between;
    margin: 0.625rem 0 2rem;
}


.dtsTypeSelect{
    display: flex;
    position: relative;
    margin-bottom: 100px;
}
.dtsTypeSelect label{
    cursor: pointer;
    flex: 1;
    max-width: 160px;
}
.dtsTypeSelect input[type="radio"] + span{
    color: #bbb;
}
.dtsTypeSelect input[type="radio"]:checked + span{
    color: #fff;
}

.dtsTypeSelect input[type="radio"] ~ p{
    display: none;
    position: absolute;
    top: 1.6rem;
    left: 0;
    padding: 10px;
    width: 100%;
    height: 100px;
    border: 1px solid #474A68;
    border-radius: 5px;
}

.dtsTypeSelect input[type="radio"]:checked ~ p{
    display: block;
}
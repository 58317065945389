.data-project-info{
    flex: 1.5;
}

.data-project-info h4{
    font-size: 1.625rem;
    border-bottom: 1px solid #405284;
    padding-bottom: 0.625rem;
    margin: 0;
}

.data-project-info ul{
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}

.data-project-info li{
    list-style-type: none;
    margin-top: 0.625rem;
    min-width: 12.5rem;
    display: flex;
}

.data-project-info li:last-child{
    width: 100%;
}

.data-project-info li h3{
    width: 3.75rem;
    margin: 0;
}

.data-project-info li p{
    width: calc(100% - 3.75rem);
    margin: 0;
}
.popup-flow{
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;
}

.popup-flow::-webkit-scrollbar{
    background: #eee;
}

.popup-flow::-webkit-scrollbar-thumb{
    background: #ccc;
}

.popup-wrapper{
    background: #fff;
    width: 100%;
    height: 100%;
    word-break: keep-all;
}

.popup-header{
    padding: 40px 0 20px;
    background: #f8f8f8;
    color: #121212;
}

.ph-ttl{
    max-width: 1080px;
    padding: 0 40px;
    margin: 0 auto;
}

.ph-ttl h5{
    font-size: 24px;
}

.ph-ttl p{
    color: #777;
}

.popup-body{
    max-width: 1080px;
    padding: 40px;
    margin: 0 auto;
    color: #121212;
}

.popup-footer{
    margin-top: 30px;
}

.popup-footer,
.popup-footer > div{
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

/***********************************************************************/
/* buttons */
/***********************************************************************/

.popupBtn{
    padding: 6px 16px;
    border-radius: 4px;
    font-family: "SCDream5", "sans-serif";
    border: none;
    transition: 0.2s;
}

.popupBtn-gray{
    background: #eee;
    color: #555;
}

.popupBtn-blue{
    background: #1242B0;
    color: #fff;
}

.popupBtn-white{
    padding: 4px 12px;
    font-size: 14px;
    background: #fff;
    border: 1px solid #ddd;
    color: #777;
}

.popupBtn:hover{
    opacity: 0.5;
}

.popupModal .btn-middle.btn-blue{
    margin: 0;
    color: #333;
    background-color: #fafafa !important;
    border-color: #ddd !important;
    height: 32px;
}

.popup-body .btn-tiny{
    background-color: #fcfcfc;
    border: 1px solid #ddd;
}

.popupModal .task-info-tab{
    margin-top: 0;
}

.popupModal .nav-tabs .nav-item.show .nav-link,
.popupModal .nav-tabs .nav-link.active{
    background: #777;
}

.popup-body input[type="checkbox"]::before{
    background: #fff;
    box-shadow: 1px 1px 2px rgb(0 0 0 / 10%) inset;
    border: 1px solid #ddd;
}

.popup-body input[type="checkbox"]:checked::after{
    border-color: #777;
}

/***********************************************************************/
/* main page */
/***********************************************************************/

.project-type-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.project-type-btn{
    text-align: center;
    flex: 1;
    max-width: 500px;
    background-color: #fff;
    border: 2px solid #ddd;
    color: #777;
    padding: 50px;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.2s;
}

.project-type-title{
    font-size: 24px;
    position: relative;
    padding-top: 25px;
    margin-bottom: 5px;
}

.project-type-title::before{
    content: '';
    position: absolute;
    top: 0;
    left: calc(50% - 20px);
    width: 40px;
    height: 5px;
    background: #777;
}

.typeSelect-ettl{
    font-size: 18px;
    font-family: "SCDream5", "sans-serif";
}

.typeSelect-img{
    width: 110px;
    height: 110px;
    margin: 40px auto;
    background-image: url("../images/icon-popup-main.png");
    background-position-y: calc(100% - 7px);
    background-repeat: no-repeat;
}

.typeSelect-img.create{
    background-position-x: 0%;
}

.typeSelect-img.select{
    background-position-x: 100%;
}

.typeSelect-sttl{
    max-width: 260px;
    margin: 0 auto;
}

.project-type-btn:hover{
    background-color: #f9fcff;
    border-color: #1242b0;
}

.project-type-btn:hover .project-type-title,
.project-type-btn:hover .typeSelect-ettl{
    color: #1242b0;
}

.project-type-btn:hover .project-type-title::before{
    background-color: #1242b0;
}

.project-type-btn:hover .typeSelect-img{
    background-position-y: 0%;
}

.project-type-split{
    font-size: 24px;
    color: #ddd;
}

/***********************************************************************/
/* table */
/***********************************************************************/

.popup-table-header{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.pth-ttl{
    font-family: "SCDream5", "sans-serif";
}

.pth-btnWrapper{
    margin-left: auto;
    display: flex;
    gap: 10px;
}

/***********************************************************************/
/* form and input */
/***********************************************************************/

.popup-form-row{
    display: flex;
    border: 1px solid #eee;
    border-right: none;
    border-left: none;
    margin-bottom: -1px;
}

.popup-form-th,
.popup-form-td{
    padding: 5px;
}

.popup-form-th{
    width: 160px;
    background-color: #FAFAFA;
    color: #777;
    font-family: "SCDream5", "sans-serif";
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.popup-form-td{
    width: calc(100% - 160px);
}

.popup-form-select label{
    padding: 5px;
    border-radius: 4px;
}

.popup-form-select label:hover{
    cursor: pointer;
    background: #fafafa;
}

.popup-form-select input[type="radio"]::before{
    background: #fff;
    box-shadow: none;
    border: 1px solid #ddd;
}

.popup-form-select input[type="radio"]:checked::after{
    width: 10px;
    height: 10px;
    bottom: 3px;
    left: 3px;
    background: #1242B0;
}

.popup-form-select p{
    color: #777;
    font-size: 14px;
    padding-left: 24px;
    margin: 0;
}

.button-radio{
    color: #777;
    font-size: 14px;
    padding-left: 24px;
    margin: 0;
}

.radio-border {
    border: 1px solid #506a70;
    padding: 5px;
}

.popup-input{
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: none;
    border: 1px solid #ddd;
    color: #121212;
    padding: 8px 15px;
}

.popupModal .childField{
    border-color: #bbb;
}

/***********************************************************************/
/* file data manager */
/***********************************************************************/

.popup-body .card-header,
.popupModal .card-header{
    display: none;
}

.popup-body .chonky-black .chonky-chonkyRoot,
.popupModal .chonky-black .chonky-chonkyRoot{
    background: #fff;
    border: 1px solid #eee;
}

.popup-body .chonky-black *,
.popupModal .chonky-black *{
    color: #777;
}

.popup-body .chonky-black .MuiOutlinedInput-notchedOutline,
.popupModal .chonky-black .MuiOutlinedInput-notchedOutline{
    background: #fff;
    box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid #ddd;
}

.popup-body .card-footer,
.popupModal .card-footer{
    border: none;
    background: none;
}

.popup-body .dropzone-black .dropzone-ui,
.popupModal .dropzone-black .dropzone-ui{
    color: #777;
    background: #fafafa;
    border: 1px solid #ddd;
    border-radius: 5px;
}

/***********************************************************************/
/* modal */
/***********************************************************************/

.popupModal .modal-content{
    background: #fff;
}

.popupModal .modal-header{
    background: #eee;
}

.popupModal .modal-title{
    color: #333;
    font-family: "SCDream5", "sans-serif";
}

.popupModal .form-label{
    color: #777;
    font-size: 14px;
}

.popupModal .form-control,
.popupModal .form-select{
    background-color: #fff !important;
    box-shadow: none;
    border: 1px solid #ddd;
    border-radius: 2px;
    padding: 8px 15px;
    color: #333;
}

.popupModal .form-select{
    background-image: url("../images/form_select-popup.png");
}

.popupModal .btn-close{
    background-image: url("../images/popup-exit.png");
}

.popupModal .btn-red{
    background: #eee;
    color: #555;
    border: none;
}

.popupModal .btn-green{
    background: #1242B0;
    color: #fff;
    border: none;
}

.breadcrumb-item + .breadcrumb-item::before {
    border: 1px solid rgba(67, 67, 67, 0.84);
    border-bottom: none;
    border-left: none;
}
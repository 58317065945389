* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    background-color: white !important;
    padding: 0;
    margin: 0;
}

#notfound {
    position: relative;
    height: 70vh;
}

#notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.notfound {
    max-width: 560px;
    width: 100%;
    padding-left: 160px;
    line-height: 1.1;
}

.notfound .notfound-404 {
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    width: 140px;
    height: 140px;
    background-size: cover;
}

.notfound .notfound-404:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-transform: scale(2.4);
    -ms-transform: scale(2.4);
    transform: scale(2.4);
    border-radius: 50%;
    background-color: #211c3b;
    z-index: -1;
}

.dark-mode .notfound .notfound-404:before {
    background-color: #2d3e51;
}

.dark-mode .notfound h1 {
    color: #d5d5d5;
}

.dark-mode .notfound h2 {
    color: #d5d5d5;
}

.dark-mode .notfound p {
    color: #d5d5d5;
}

.dark-mode .notfound a {
    color: #2fc4d1;
}

.notfound h1 {
    font-family: 'Nunito', sans-serif;
    font-size: 65px;
    font-weight: 700;
    margin-top: 0px;
    margin-bottom: 10px;
    color: #c2c8e9;
    text-transform: uppercase;
}

.notfound h2 {
    font-family: 'NanumBarunGothic', sans-serif;
    font-size: 21px;
    font-weight: 400;
    margin: 0;
    text-transform: uppercase;
    color: #babbc3;
}

.notfound p {
    font-family: 'NanumBarunGothic', sans-serif;
    color: #999fa5;
    font-weight: 400;
}

.notfound a {
    font-family: 'Nunito', sans-serif;
    display: inline-block;
    font-weight: 700;
    border-radius: 40px;
    text-decoration: none;
    color: #5fb4e3;
}

@media only screen and (max-width: 767px) {
    .notfound .notfound-404 {
        width: 110px;
        height: 110px;
    }

    .notfound {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 110px;
    }
}
